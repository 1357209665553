












































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import ReportSlotsCard from "@/views/Report/ReportSlotsCard.vue";
import { IReportTrainingDurationInDays } from "@/interfaces/IReportTrainingDurationInDays";
import ReportCard from "@/views/Report/ReportCard.vue";
import ReportHeader from "@/views/Report/HelperComponents/ReportHeader.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { IAnsweredQuestionsPerDrivingSchoolReport } from "@/interfaces/IAnsweredQuestionsPerDrivingSchoolReport";

@Component({
  components: { ReportSlotsCard, Hint, HintMessage, ReportCard, ReportHeader, PrintButton, DocumentButton },
})
export default class ReportQuestionAnsweredToday extends Vue {
  public name = "ReportQuestionAnsweredToday";

  @Prop({ required: false, type: Object as PropType<IReportTrainingDurationInDays> })
  public data!: any;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;

  @Prop()
  public filterData!: any;

  @Prop({ type: String })
  public dailyDataStartPeriod!: string;

  @Prop({ type: Boolean, default: () => false })
  public dailyDataStartPeriodLoading!: boolean;

  public visible = false;

  public get hasValue() {
    return (v: any) => !isNaN(parseFloat(v));
  }

  public get getNumberOfFinishedPreExams() {
    return this.data ? this.data?.numberOfFinishedPreExams : "";
  }

  public get getDurationOfLearning() {
    return this.data ? this.data?.averageEducationDuration : "";
  }

  public get getNumberOfTheoryQuestionsAnswered() {
    return this.data ? this.data?.numberOfTheoryQuestionsAnswered : "";
  }

  public get getNumberOfTheoryQuestionsAnsweredToday() {
    return this.data ? this.data?.numberOfTheoryQuestionsAnsweredToday : "";
  }

  public showBlocks(e: boolean) {
    this.visible = e;
  }
}
