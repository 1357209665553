



















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import eventBus from "@/plugins/EventBus";
import moment from "moment";

@Component({
  components: { FscSimpleCard },
})
export default class ReportHeader extends Vue {
  public name = "ReportHeader";
  public showReference = false;
  public info: any = { period: "", filterData: "" };

  @Prop({ default: "report.training_in_days" })
  public translationTitleKey!: string;

  @Prop({ default: false })
  public daily!: boolean;

  @Prop({ default: true })
  public showSwitch!: boolean;

  @Watch("info", { deep: true, immediate: true })
  public onDataChange(newVal: any) {
    return newVal;
  }
  public mounted() {
    eventBus.$on("selected-filter", (info: any) => {
      if (this.daily) {
        this.info.period = moment(info.startDate).format("DD.MM.YYYY");
      } else {
        this.info.period = [moment(info.startDate).format("DD.MM.YYYY"), moment(info.endDate).format("DD.MM.YYYY")].join(" - ");
      }
      this.info.filterData = "";
      const filterDataArr = [];
      if (info.licenseClasses) {
        filterDataArr.push(info.licenseClasses);
      }
      if (info.instructors) {
        filterDataArr.push(info.instructors);
      }
      if (info.branches) {
        filterDataArr.push(info.branches);
      }

      this.info.filterData = filterDataArr.join("| ");
    });
  }
}
